@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "dmsans";
  src: url("/files/fonts/DMSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@layer base {
  body {
    @apply relative z-1 bg-whiten text-base font-normal text-body;
    font-family: "dmsans";
  }
}

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }

  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}

/* third-party libraries CSS */

.tableCheckbox:checked ~ div span {
  @apply opacity-100;
}

.tableCheckbox:checked ~ div {
  @apply border-primary bg-primary;
}

.apexcharts-legend-text {
  @apply !text-body dark:!text-bodydark;
}

.apexcharts-text {
  @apply !fill-body dark:!fill-bodydark;
}

.apexcharts-xcrosshairs {
  @apply !fill-stroke dark:!fill-strokedark;
}

.apexcharts-gridline {
  @apply !stroke-stroke dark:!stroke-strokedark;
}

.apexcharts-series.apexcharts-pie-series path {
  @apply dark:!stroke-transparent;
}

.apexcharts-legend-series {
  @apply !inline-flex gap-1.5;
}

.apexcharts-tooltip.apexcharts-theme-light {
  @apply dark:!border-strokedark dark:!bg-boxdark;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  @apply dark:!border-strokedark dark:!bg-meta-4;
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  @apply dark:!border-meta-4 dark:!bg-meta-4 dark:!text-bodydark1;
}

/* .apexcharts-xaxistooltip-bottom:after {
    @apply !border-b-gray dark:!border-b-meta-4;
} */

/* .apexcharts-xaxistooltip-bottom:before {
    @apply !border-b-gray dark:!border-b-meta-4;
} */

/* .apexcharts-xaxistooltip-bottom {
    @apply !rounded !border-none !bg-gray !text-xs !font-medium !text-neutral-800 dark:!text-white;
} */

.apexcharts-tooltip-series-group {
  @apply !pl-1.5;
}

.flatpickr-wrapper {
  @apply w-full;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply !fill-primary;
}

.flatpickr-calendar.arrowTop:before {
  @apply dark:!border-b-boxdark;
}

.flatpickr-calendar.arrowTop:after {
  @apply dark:!border-b-boxdark;
}

.flatpickr-calendar {
  @apply !p-6 dark:!bg-boxdark dark:!text-bodydark dark:!shadow-8 2xsm:!w-auto;
}

.flatpickr-day {
  @apply dark:!text-bodydark dark:hover:!border-meta-4 dark:hover:!bg-meta-4;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply !top-7 dark:!fill-white dark:!text-white;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  @apply !left-7;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  @apply !right-7;
}

span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
  @apply dark:!fill-white dark:!text-white;
}

.flatpickr-day.inRange {
  box-shadow:
    -5px 0 0 #f3f4f6,
    5px 0 0 #f3f4f6 !important;
  @apply dark:!shadow-7;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  @apply !border-[#F3F4F6] !bg-[#F3F4F6] dark:!border-meta-4 dark:!bg-meta-4;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.selected,
.flatpickr-day.endRange {
  @apply dark:!text-white;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #3c50e0;
  @apply !border-primary !bg-primary hover:!border-primary hover:!bg-primary;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #3c50e0;
}

.map-btn .jvm-zoom-btn {
  @apply flex h-7.5 w-7.5 items-center justify-center rounded border border-stroke bg-white px-0 pb-0.5 pt-0 text-2xl leading-none text-body hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:bg-meta-4 dark:text-bodydark dark:hover:border-primary dark:hover:bg-primary dark:hover:text-white;
}

.mapOne .jvm-zoom-btn {
  @apply !bottom-0 !left-auto !top-auto;
}

.mapOne .jvm-zoom-btn.jvm-zoomin {
  @apply !right-10;
}

.mapOne .jvm-zoom-btn.jvm-zoomout {
  @apply !right-0;
}

.taskCheckbox:checked ~ .box span {
  @apply opacity-100;
}

.taskCheckbox:checked ~ p {
  @apply line-through;
}

.taskCheckbox:checked ~ .box {
  @apply border-primary bg-primary dark:border-primary;
}

.custom-input-date::-webkit-calendar-picker-indicator {
  background: transparent;
}

input[type="search"]::-webkit-search-cancel-button {
  @apply appearance-none;
}

.custom-input-date::-webkit-calendar-picker-indicator {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

[x-cloak] {
  display: none !important;
}

.editor-div p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}
